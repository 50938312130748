import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delivery-not-available',
  standalone: true,
  templateUrl: './delivery-not-available.component.html',
  styleUrls: ['./delivery-not-available.component.scss']
})
export class DeliveryNotAvailableComponent implements OnInit {

  constructor() { }

  @Output() showBranchPopup = new EventEmitter<any>();
  ngOnInit(): void {
  }

  changeBranch():void{
    this.showBranchPopup.emit(true);
  }
}
