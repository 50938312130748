import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DeliverymodeComponent } from '../pages/food-menu/deliverymode/deliverymode.component';

@Component({
  selector: 'app-store-closed',
  standalone:true,
  templateUrl: './store-closed.component.html',
  styleUrls: ['./store-closed.component.scss'],
})
export class StoreClosedComponent implements OnInit{
  @Output() showBranchPopup = new EventEmitter<any>();
  multibranch:string ='';

  ngOnInit():void{
    let branchType: any = localStorage.getItem('branchType');
    console.log(branchType,'branchType');
    this.multibranch = branchType;
  }
  changeBranch():void{
    this.showBranchPopup.emit(true);
  }
}
